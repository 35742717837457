import gql from 'graphql-tag';

export class SendEmail {
  to: String;
  subject: String;
  text: String;
  attachments: Object[];
  constructor(email?: SendEmail) {
    Object.assign(this, email);
  }
}

export const sendEmail: any = gql`
  mutation sendMail($emailData: JSON){
    sendMail(
      emailData: $emailData
    )
  }
`;

export const sendMailFromSystem: any = gql`
  mutation sendMailFromSystem($emailData: JSON){
    sendMailFromSystem(
      emailData: $emailData
    )
  }
`;
