import gql from 'graphql-tag';

export const uploadImageProfile: any = gql`
  mutation uploadImageProfile($entity: String!, $filename: String!, $data: String!){
    uploadImageProfile(
      entity: $entity,
      filename: $filename,
      data: $data
    )
  }
`;
