import gql from 'graphql-tag';

export const getCreditById: any = gql`
  query getCreditById($_id: ID!) {
    getCreditById(_id: $_id) {
      _id
      creditCode
      capturedBy {
        _id
        employeeName
      }
      creditInfo {
        requestedAmount
        interest
        iva
        approvedAmount
        amountDelivered
        balance
        capital
        paidCapital
        moratorium
        lastPaidDate
        nextPaidDate
        typeCreditPeriod
        creditPeriod
        currentPeriod
        paymentForPeriod
      }
      disbursement {
        date
      }
      creditData {
        _id
        applicants {
          requestedAmount
          approvedAmount
          balance
          capital
          interest
          iva
          paymentForPeriod
          client {
            _id
            clientCode
            clientGeneralInfo {
              firstname
              secondname
              firstLastname
              secondLastname
            }
          }
        }
        group {
          _id
          groupCode
          groupName
          members {
            client {
              _id
              clientCode
              clientGeneralInfo {
                firstname
                secondname
                firstLastname
                secondLastname
              }
            }
            isRepresentative
            isAlternate
          }
          cycle
          status
          addressMeeting {
            latitude
            longitude
            meetingDay
            meetingHour
          }
        }
        client {
          _id
          clientCode
          clientGeneralInfo {
            firstname
            secondname
            firstLastname
            secondLastname
          }
          clientAddress {
            latitude
            longitude
          }
        }
        cycle
      }
    }
  }
`;

export const getListOfEmployeeWaitingCredits: any = gql`
  query getListOfEmployeeWaitingCredits($employee: ID!) {
    getListOfEmployeeWaitingCredits(employee: $employee) {
      _id
      creditCode
      creditInfo {
        requestedAmount
        approvedAmount
        amountDelivered
        balance
        capital
        paidCapital
        moratorium
        lastPaidDate
        nextPaidDate
        typeCreditPeriod
        creditPeriod
        currentPeriod
        paymentForPeriod
        status
      }
      creditData {
        _id
        group {
          groupCode
          groupName
        }
        client {
          _id
          clientCode
          clientGeneralInfo {
            firstname
            secondname
            firstLastname
            secondLastname
          }
        }
        cycle
      }
    }
  }
`;
export const getListOfEmployeeActiveCredits: any = gql`
  query getListOfEmployeeActiveCredits($employee: ID!) {
    getListOfEmployeeActiveCredits(employee: $employee) {
      _id
      creditCode
      creditInfo {
        requestedAmount
        approvedAmount
        amountDelivered
        balance
        capital
        paidCapital
        moratorium
        lastPaidDate
        nextPaidDate
        typeCreditPeriod
        creditPeriod
        currentPeriod
        paymentForPeriod
        status
      }
      creditData {
        _id
        group {
          _id
          groupCode
          groupName
        }
        client {
          _id
          clientCode
          clientGeneralInfo {
            firstname
            secondname
            firstLastname
            secondLastname
          }
        }
        cycle
      }
    }
  }
`;

export const addPayment: any = gql`
  mutation addPayment(
    $credit: ID!
    $employee: ID!
    $paymentAmount: Float
    $paymentType: String
    $paymentBank: String
    $paymentBranch: String
    $paymentFolio: String
    $paymentProof: String
    $paymentDate: Date
    $paymentReceipt: String
    $paymentClients: [JSON]
    $note: String
  ) {
    addPayment(
      credit: $credit
      employee: $employee
      paymentAmount: $paymentAmount
      paymentType: $paymentType
      paymentBank: $paymentBank
      paymentBranch: $paymentBranch
      paymentFolio: $paymentFolio
      paymentProof: $paymentProof
      paymentDate: $paymentDate
      paymentReceipt: $paymentReceipt
      paymentClients: $paymentClients
      note: $note
    ) {
      _id
    }
  }
`;

export const registerPayment: any = gql`
  mutation registerPayment(
    $creditID: ID!,
    $paymentID: ID!
    $dataPayment: JSON!
  ) {
    registerPayment(
      creditID: $creditID,
      paymentID: $paymentID
      dataPayment: $dataPayment
    )
  }
`;

export const acceptPay: any = gql`
  mutation acceptPay(
    $paymentID: ID!
    $data: JSON!
    $notes: String!
  ) {
    acceptPay(
      paymentID: $paymentID
      data: $data
      notes: $notes
    )
  }
`;

export const noAcceptPay: any = gql`
  mutation noAcceptPay(
    $paymentID: ID!
    $notes: String!
  ) {
    noAcceptPay(
      paymentID: $paymentID
      notes: $notes
    )
  }
`;

export const loadXLSXNominaBulkLoad: any = gql`
  mutation loadXLSXNominaBulkLoad($data: JSON!) {
    loadXLSXNominaBulkLoad(data: $data)
  }
`;

export const confirmNominaBulkLoad: any = gql`
  mutation confirmNominaBulkLoad($data: JSON!) {
    confirmNominaBulkLoad(data: $data)
  }
`;

export const registerPay: any = gql`
  mutation registerPay($data: JSON!) {
    registerPay(data: $data)
  }
`;

export const updatedPay: any = gql`
  mutation updatedPay($data: JSON!) {
    updatedPay(data: $data)
  }
`;

export const deletePay: any = gql`
  mutation deletePay($paymentID: ID!) {
    deletePay(paymentID: $paymentID)
  }
`;

export const changePaymentDate: any = gql`
  mutation changePaymentDate($data: JSON!) {
    changePaymentDate(data: $data)
  }
`;

export const recalculePaymentsCredit: any = gql`
  mutation recalculePaymentsCredit($creditID: ID!) {
    recalculePaymentsCredit(creditID: $creditID)
  }
`;

export const condonatePayment: any = gql`
  mutation condonatePayment($creditID: ID!, $paymentID: ID!, $paymentCode: String!, $condonateData: JSON, $restart: Boolean) {
    condonatePayment(creditID: $creditID, paymentID: $paymentID, paymentCode: $paymentCode, condonateData: $condonateData, restart: $restart)
  }
`;

export const viewerPayment: any = gql`
query viewerPayment($start: Int, $show: Int, $filter: JSON, $sort: JSON){
  viewerPayment(start: $start, show: $show, filter: $filter, sort: $sort){
    result {
      _id
      paymentFolio
      credit{
        _id
        creditCode
        creditType
        creditData{
          _id
          client {
            _id
            clientCode
            clientName
            clientGeneralInfo {
              firstname
              secondname
              firstLastname
              secondLastname
            }
          }
          group {
            _id
            groupCode
            groupName
          }
        }
      }
      chargeBy{
        _id
        employeeName
      }
      paymentType
      paymentBranch
      paymentIdMov
      paymentDate
      paymentCapital
      paymentInterest
      paymentIVA
      paymentTotal
      client {
        client{
          _id
          clientName
        }
      }
      status
      createdDate
    }
    pageInfo{
      hasNextPage
      hasPreviousPage
      totalItems
      totalPages
      currentPage
    }
  }
}
`;

export const viewerBulkLoad: any = gql`
query viewerBulkLoad($start: Int, $show: Int, $filter: JSON, $sort: JSON){
  viewerBulkLoad(start: $start, show: $show, filter: $filter, sort: $sort){
    result {
      _id
      loadID
      typeLoad
      totalPaymentLoad
      payments {
        _id
        paymentFolio
      }
      createdDate
      updatedDate
    }
    pageInfo{
      hasNextPage
      hasPreviousPage
      totalItems
      totalPages
      currentPage
    }
  }
}
`;

export const getPaymentByID: any = gql`
query getPaymentByID($paymentID: ID!){
  getPaymentByID(paymentID: $paymentID) {
    _id
    credit{
      _id
      creditType
      creditCode
    }
    branchCharge{
      _id
      branchCode
      name
    }
    chargeBy{
      _id
      employeeCode
      employeeName
    }
    paymentFolio
    paymentPlace
    paymentType
    paymentBranch
    paymentIdMov
    paymentDate
    paymentCapital
    paymentInterest
    paymentIVA
    paymentMoratorium
    paymentIvaMoratorium
    paymentCE
    paymentIvaCE
    paymentOthers
    paymentTotal
    paymentNotAssigned
    paymentCondition
    paymentReference
    evidence
    status
    note
    typeCurrency
    currencyChange
    paymentCurrency
    checkNote
    client {
      typeClient
      client{
        _id
        clientCode
        clientName
      }
      paymentCapital
      paymentInterest
      paymentIVA
      paymentMoratorium
      paymentIvaMoratorium
      paymentCE
      paymentIvaCE
      paymentOthers
      paymentTotal
      paymentNotAssigned
    }
  }
}
`;


export const getPaymentByIDBasic: any = gql`
query getPaymentByID($paymentID: ID!){
  getPaymentByID(paymentID: $paymentID) {
    _id
    paymentFolio
    paymentDate
  }
}
`;

export const viewerPaymentBasic: any = gql`
query viewerPayment($start: Int, $show: Int, $filter: JSON, $sort: JSON){
  viewerPayment(start: $start, show: $show, filter: $filter, sort: $sort){
    result {
      _id
      paymentFolio
      paymentDate
      paymentTotal
      status
      createdDate
    }
    pageInfo{
      hasNextPage
      hasPreviousPage
      totalItems
      totalPages
      currentPage
    }
  }
}
`;


export const getEvidencePayment: any = gql`
query getEvidencePayment($paymentID: ID!){
  getEvidencePayment(paymentID: $paymentID)
}
`;


export const getBulkloadByID: any = gql`
query getBulkloadByID($bulkloadID: ID!){
  getBulkloadByID(bulkloadID: $bulkloadID) {
      loadID
      typeLoad
      totalPaymentLoad
      status
      payments {
        _id
        paymentFolio
        paymentDate
        paymentTotal
        paymentType
        credit{
          _id
          creditCode
          creditType
          creditData{
            _id
            client{
              _id
              clientName
            }
            group{
              _id
              groupName
            }
          }
        }
      }
      createdDate
      updatedDate
  }
}
`;



export const cancelMassiveVharge: any = gql`
  mutation cancelMassiveVharge($bulkloadID: ID!) {
    cancelMassiveVharge(bulkloadID: $bulkloadID)
  }
`;

export const getPaymentCreditCondonation: any = gql`
query getPaymentCreditCondonation($paymentID: ID!, $paymentCode: Int!){
  getPaymentCreditCondonation(paymentID: $paymentID, paymentCode: $paymentCode) {
    _id
    payments {
      paymentCode
      paymentNum
      paymentType
      paymentCapital
      paymentInterest
      paymentIVA
      paymentMoratorium
      paymentIvaMoratorium
      paymentCE
      paymentIvaCE
      paymentOthers
      paymentTotal
      paidCapital
      paidInterest
      paidIVA
      paidMoratorium
      paidIvaMoratorium
      paidCE
      paidIvaCE
      paidOthers
      paidTotal
      paymentDate
      paymentLimit
      paymentStatus
      paymentNote
      paymentExpiredDays
      chargedBy {
        _id
        employeeCode
        employeeName
      }
      condonate
      condonateCE
      condonateMora
      condonateInterest
      condonateDate
    }
  }
}
`;
