import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NotFoundComponent {

    public router: Router;

    constructor(router: Router) {
        this.router = router;
    }

    goToStart(): void {
        // 1 console.log(this.router.url);
        this.router.navigate(['pages/dashboard']);
    }

}
