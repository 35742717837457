import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { getListOfUserTypes, getUserTypeById } from '../../model/permissions/userType';
import { checkEmployeePassword } from '../../model/employee/employee';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable } from 'rxjs';

import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class AccountService {
  private geoLocation = null;
  private JwtHelperService: JwtHelperService = new JwtHelperService();

  constructor(private apollo: Apollo, private router: Router, private permissionsService: NgxPermissionsService) { }

  logout() {
    sessionStorage.clear();
    this.permissionsService.flushPermissions();
    this.router.navigate(['/login'], { replaceUrl: true });
  }

  getListOfUserTypes() {
    // // 1 console.log('Realizando consulta');
    // // 1 console.log(JSON.stringify(this.apollo.getClient().link));
    return this.apollo.query({
      query: getListOfUserTypes,
      fetchPolicy: 'network-only'
    });
  }

  getUserTypeById(id) {
    return this.apollo.query({
      query: getUserTypeById,
      variables: { _id: id },
      fetchPolicy: 'network-only'
    });
  }

  loginWithToken(token) {
    if (token) {
      this.getUserTypeById(this.JwtHelperService.decodeToken(token).userType)
        .subscribe(resUsers => {
          this.permissionsService.flushPermissions();
          this.permissionsService.loadPermissions(
            this.checarPermisos(
              resUsers.data['getUserTypeById'].permisos
            )
          );
          // // 1 console.log('conectado con tokens');
        });
    }
  }

  loadPermissionInApp(): Promise<Boolean> {
    return new Promise((resolve, reject) => {
      if (sessionStorage.getItem('token')) {
        // // 1 console.log('Recibiendo lista');
        // // 1 console.log(this.JwtHelperService.decodeToken(sessionStorage.getItem('token')).userType);
        this.getUserTypeById(this.JwtHelperService.decodeToken(sessionStorage.getItem('token')).userType).subscribe(
          res => {
            // // 1 console.log('ResUser');
            this.permissionsService.flushPermissions();
            this.permissionsService.loadPermissions(
              this.checarPermisos(
                res.data['getUserTypeById'].permisos
              )
            );
            // // 1 console.log('conectado con tokens');
            // this.inciarGeolocalizacion();
            resolve(true);
          },
          err => {
            // // 1 console.log('Error');
          }
        );
      } else {
        reject('Error token no encontrado');
      }
    });
  }

  login(employeeCode, password) {
    sessionStorage.clear();
    this.permissionsService.flushPermissions();
    return new Observable(conectado => {
      this.apollo
        .query({
          query: checkEmployeePassword,
          variables: { employeeCode: employeeCode, password: password },
          fetchPolicy: 'network-only'
        })
        .subscribe(
          res => {
            if (res.data['checkEmployeePassword'] != null) {
              sessionStorage.setItem('token', res.data['checkEmployeePassword']);
              this.getUserTypeById(this.JwtHelperService.decodeToken(sessionStorage.getItem('token')).userType)
                .subscribe(resUsers => {
                  // // 1 console.log(resUsers);
                  this.permissionsService.flushPermissions();
                  this.permissionsService.loadPermissions(
                    this.checarPermisos(
                      resUsers.data['getUserTypeById'].permisos
                    )
                  );
                  conectado.next(true);
                  conectado.complete();
                });
            }
          },
          err => {
            conectado.error(err);
          }
        );
    });
  }

  checarPermisos(permisos) {
    const permisosObtenidos = this.getArrayPermisos(permisos);
    // // 1 console.log(permisosObtenidos);
    // 1 console.log(permisosObtenidos);
    return permisosObtenidos;
  }

  getArrayPermisos(permisos) {
    const response = [];
    for (const key in permisos) {
      if (typeof permisos[key] === 'object' && permisos[key] !== null) {
        const subPermiso = this.getArrayPermisos(permisos[key]);
        for (const key2 in subPermiso) {
          if (subPermiso[key2]) {
            response.push(key + '-' + subPermiso[key2]);
          }
        }
      } else if (typeof permisos[key] === 'boolean') {
        if (permisos[key]) {
          response.push(key);
        }
      }
    }
    // // 1 console.log(response);
    return response;
  }
}
