export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyB-bn2Dcjf_dtrOLmns1aKIwfOGwD3BzKg',
    authDomain: 'braavo-b7305.firebaseapp.com',
    databaseURL: 'https://braavo-b7305.firebaseio.com',
    projectId: 'braavo-b7305',
    storageBucket: 'braavo-b7305.appspot.com',
    messagingSenderId: '30179666805'
  },
  googleMaps: {
    apiKey: 'AIzaSyDe_oVpi9eRSN99G4o6TwVjJbFBNr58NxE'
  }
};
