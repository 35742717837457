import { Injectable } from '@angular/core';
import {
  addEmployee,
  getListOfEmployees,
  getListOfMyEmployees,
  getListOfEmployeesLocation,
  getEmployeeById,
  updateEmployee,
  updatePassword,
  deleteEmployee,
  getJobPositions,
  getEmployeeBasicInfoById,
  getIDEmployeeByCode,
  viewEmployeeData,
  viewerEmployee,
  verifyPasswordEmployee,
  getEmployeesSubordinates,
  changeStatusPermissionEmployee,
  autorrizedEmployeeUser,
  updateZoneEmployee
} from '../../model/employee/employee';
import { Apollo } from 'apollo-angular';
import { getListOfUserTypesToLevel, getListOfMyUserTypes } from '../../model/permissions/userType';

@Injectable()
export class EmployeeService {
  private listOfMyUserTypes: any = null;
  constructor(private apollo: Apollo) { }

  addEmployee(employee): any {
    return this.apollo.mutate({ mutation: addEmployee, variables: employee });
  }

  updateEmployee(employee): any {
    return this.apollo.mutate({
      mutation: updateEmployee,
      variables: employee
    });
  }

  deleteEmployee(id, data) {
    return this.apollo.mutate({
      mutation: deleteEmployee,
      variables: {
        employeeID: id,
        data: data
      }
    });
  }

  updatePassword(password, employee): any {
    return this.apollo.mutate({
      mutation: updatePassword,
      variables: { pass: password, employeeID: employee }
    });
  }

  updateZoneEmployee(employee, zone): any {
    return this.apollo.mutate({
      mutation: updateZoneEmployee,
      variables: { zoneID: zone, employeeID: employee }
    });
  }

  getListOfEmployees() {
    return this.apollo.watchQuery({
      query: getListOfEmployees,
    }).valueChanges;
  }
  getListOfMyEmployees() {
    return this.apollo.watchQuery({
      query: getListOfMyEmployees,
      fetchPolicy: 'network-only'
    }).valueChanges;
  }
  getListOfEmployeesLocation() {
    return this.apollo.watchQuery({
      query: getListOfEmployeesLocation,
    }).valueChanges;
  }
  getEmployeeById(id) {
    return this.apollo.watchQuery({
      query: getEmployeeById,
      fetchPolicy: 'network-only',
      variables: { _id: id }
    }).valueChanges;
  }

  viewEmployeeData(id) {
    return this.apollo.watchQuery({
      query: viewEmployeeData,
      fetchPolicy: 'network-only',
      variables: { _id: id }
    }).valueChanges;
  }


  getIDEmployeeByCode(code) {
    return this.apollo.watchQuery({
      query: getIDEmployeeByCode,
      fetchPolicy: 'network-only',
      variables: { employeeCode: code }
    }).valueChanges;
  }

  getEmployeeBasicInfoById(id) {
    return this.apollo.watchQuery({
      query: getEmployeeBasicInfoById,
      fetchPolicy: 'network-only',
      variables: { _id: id }
    }).valueChanges;
  }

  getListOfUserTypesToLevel(levelCode) {
    return this.apollo.watchQuery({
      query: getListOfUserTypesToLevel,
      variables: { levelCode: levelCode },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  getListOfMyUserTypes() {
    if (this.listOfMyUserTypes === null) {
      this.listOfMyUserTypes = this.apollo.watchQuery({
        query: getListOfMyUserTypes
      });
      return this.listOfMyUserTypes.valueChanges;
    } else {
      this.listOfMyUserTypes.refetch()
      return this.listOfMyUserTypes.valueChanges;
    }
  }
  getJobPositions() {
    return this.apollo.watchQuery({ query: getJobPositions }).valueChanges;
  }

  viewerEmployee(start, show, filter, sort) {
    return this.apollo.watchQuery({
      query: viewerEmployee,
      variables: {
        start: start,
        show: show,
        filter: filter,
        sort: sort
      },
      fetchPolicy: 'network-only'
    }).valueChanges;
  }

  verifyPasswordEmployee(data) {
    return this.apollo.watchQuery({
      query: verifyPasswordEmployee,
      fetchPolicy: 'network-only',
      variables: { pass: data.pass }
    }).valueChanges;
  }

  autorrizedEmployeeUser(data) {
    return this.apollo.watchQuery({
      query: autorrizedEmployeeUser,
      fetchPolicy: 'network-only',
      variables: { user: data.user, pass: data.pass }
    }).valueChanges;
  }

  changeStatusPermissionEmployee(employee, status, permission): any {
    return this.apollo.mutate({
      mutation: changeStatusPermissionEmployee,
      variables: { employeeID: employee, status: status, permission: permission }
    });
  }

  getEmployeesSubordinates(employee) {
    return this.apollo.watchQuery({
      query: getEmployeesSubordinates,
      fetchPolicy: 'network-only',
      variables: { employeeID: employee }
    }).valueChanges;
  }
}
