import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import {
  uploadDocument, getDocumentById, getDocuments,
  getDocumentServer, getStatusDocumentServer, uploadDocumentServer, removeDocumentServer
} from '../../model/general/document.model'

@Injectable()
export class DocumentService {

  constructor(
    private apollo: Apollo
  ) { }

  getDocumentById(id) {
    return this.apollo.watchQuery({
      query: getDocumentById,
      fetchPolicy: 'network-only',
      variables: { _id: id }
    }).valueChanges;
  }

  getDocuments(entity, id, clientID) {
    return this.apollo.watchQuery({
      query: getDocuments,
      fetchPolicy: 'network-only',
      variables: { entity: entity, _id: id, client: clientID }
    }).valueChanges;
  }

  uploadDocument(entity, id, documento, clientID): any {
    return this.apollo.mutate({
      mutation: uploadDocument,
      variables: {
        entity: entity,
        _id: id,
        documento: documento,
        client: clientID
      }
    });
  }

  // Subir archivos directamente al servidor
  getDocumentServer(type, id, data = {}, entity) {
    return this.apollo.watchQuery({
      query: getDocumentServer,
      variables: {
        id: id,
        type: type,
        data: data,
        entity: entity
      },
      fetchPolicy: 'cache-first'
    }).valueChanges;
  }

  getStatusDocumentServer(type, id, data = {}, entity) {
    return this.apollo.watchQuery({
      query: getStatusDocumentServer,
      variables: {
        id: id,
        type: type,
        data: data,
        entity: entity
      },
      fetchPolicy: 'network-only'
    }).valueChanges;
  }

  uploadDocumentServer(document, type, id, entity): any {
    return this.apollo.mutate({
      mutation: uploadDocumentServer,
      variables: {
        document: document,
        type: type,
        id: id,
        entity: entity
      }
    });
  }

  removeDocumentServer(type, id, data = {}, entity): any {
    return this.apollo.mutate({
      mutation: removeDocumentServer,
      variables: {
        type: type,
        id: id,
        data: data,
        entity: entity
      }
    });
  }

}
