import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SendMailComponent } from './send-mail/send-mail.component';
import { StatusConnectionsComponent } from './status-connections/status-connections.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GeneralService } from '../../services/general/general.service';
import { SearchModalComponent } from './search-modal/search-modal.component';
import { AgmCoreModule } from '@agm/core';
import { GoogleMapsSelectPointComponent } from './google-maps-select-point/google-maps-select-point.component';
import { ImageUploaderComponent } from './image-uploader/image-uploader.component';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { DocumentManagerComponent } from './document-manager/document-manager.component';
import { AmortizationTableComponent } from './amortization-table/amortization-table.component';
import { VerificationBuroComponent } from '../client/burodecredito/verification-buro/verification-buro.component';
import { GoogleMapsViewPointComponent } from './google-maps-view-point/google-maps-view-point.component';
import { DocumentService } from '../../services/general/document.service';
import { EnrolFingerprintComponent } from './enrol-fingerprint/enrol-fingerprint.component';
import { PaymentService } from '../../services/payment/payment.service';
import { MomentModule } from 'angular2-moment';
import 'moment/locale/es';
import { ViewTableComponent } from './view-table/view-table.component';
import { TagInputModule } from 'ngx-chips';
import { ActionModalsComponent } from './action-modals/action-modals.component';
import { LoadingModalComponent } from './loading-modal/loading-modal.component';
import { EmployeeService } from '../../services/employee/employee.service';
import { AccountService } from '../../services/account/account.service';
import { ImageCroppComponent } from './image-cropp/image-cropp.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SignatureCaptureComponent } from './signature-capture/signature-capture.component';
import { HomeVerificationComponent } from './home-verification/home-verification.component';
import { GoogleMapsMultiplePointComponent } from './google-maps-multiple-point/google-maps-multiple-point.component';
import { ViewWarrantyComponent } from './view-warranty/view-warranty.component';
import { ViewAvalComponent } from './view-aval/view-aval.component';
import { DocumentationCreditComponent } from './documentation-credit/documentation-credit.component';
import { ChangeDisbursementDateComponent } from './change-disbursement-date/change-disbursement-date.component';
import { ChangeAuthorizedAmountComponent } from './change-authorized-amount/change-authorized-amount.component';
import { ChangeStatusCreditComponent } from './change-status-credit/change-status-credit.component';
import { ChangePeriodCreditComponent } from './change-period-credit/change-period-credit.component';
import { ValidationListCreditPLDComponent } from './validation-list-credit-pld/validation-list-credit-pld.component';
import { ChangeOriginResourcesComponent } from './change-origin-resources/change-origin-resources.component';
import { ChangeDisbursementTypeComponent } from './change-disbursement-type/change-disbursement-type.component';
import { ChangeWarrantyComponent } from './change-warranty/change-warranty.component';
import { CancelCreditComponent } from './cancel-credit/cancel-credit.component';
import { PasswordConfirmComponent } from './password-confirm/password-confirm.component';
import { RegisterTransferenceComponent } from './register-transference/register-transference.component';
import { RegisterCheckComponent } from './register-check/register-check.component';
import { ViewDataBlackListComponent } from './view-data-black-list/view-data-black-list.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { UserConfirmComponent } from './user-confirm/user-confirm.component';
import { GetAuthorizationsComponent } from './get-authorizations/get-authorizations.component';
import { UserGetSignatureFingerprintComponent } from './user-get-signature-fingerprint/user-get-signature-fingerprint.component';
import { CompleteActionComponent } from './complete-action/complete-action.component';
import { GuidesPaymentsComponent } from './guides-payments/guides-payments.component';
import { AmountsCondonationComponent } from './amounts-condonation/amounts-condonation.component';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    AgmCoreModule,
    // AngularMyDatePickerModule
    MomentModule,
    TagInputModule,
    ImageCropperModule,
    NgxPaginationModule,
    AngularMyDatePickerModule
  ],
  declarations: [
    SendMailComponent,
    StatusConnectionsComponent,
    SearchModalComponent,
    GoogleMapsSelectPointComponent,
    GoogleMapsViewPointComponent,
    ImageUploaderComponent,
    FileUploaderComponent,
    DocumentManagerComponent,
    AmortizationTableComponent,
    VerificationBuroComponent,
    EnrolFingerprintComponent,
    ViewTableComponent,
    ActionModalsComponent,
    LoadingModalComponent,
    ImageCroppComponent,
    SignatureCaptureComponent,
    HomeVerificationComponent,
    GoogleMapsMultiplePointComponent,
    ViewWarrantyComponent,
    ViewAvalComponent,
    DocumentationCreditComponent,
    ChangeDisbursementDateComponent,
    ChangeAuthorizedAmountComponent,
    ChangeStatusCreditComponent,
    ChangePeriodCreditComponent,
    ValidationListCreditPLDComponent,
    ChangeOriginResourcesComponent,
    ChangeDisbursementTypeComponent,
    ChangeWarrantyComponent,
    CancelCreditComponent,
    PasswordConfirmComponent,
    RegisterTransferenceComponent,
    RegisterCheckComponent,
    ViewDataBlackListComponent,
    UserConfirmComponent,
    GetAuthorizationsComponent,
    UserGetSignatureFingerprintComponent,
    CompleteActionComponent,
    GuidesPaymentsComponent,
    AmountsCondonationComponent,
  ],
  exports: [
    SendMailComponent,
    StatusConnectionsComponent,
    SearchModalComponent,
    GoogleMapsSelectPointComponent,
    GoogleMapsViewPointComponent,
    ImageUploaderComponent,
    FileUploaderComponent,
    DocumentManagerComponent,
    AmortizationTableComponent,
    VerificationBuroComponent,
    EnrolFingerprintComponent,
    SignatureCaptureComponent,
    GoogleMapsMultiplePointComponent,
    AmountsCondonationComponent
  ],
  providers: [AccountService, GeneralService, DocumentService, PaymentService, EmployeeService],
  entryComponents: [
    AmortizationTableComponent,
    SearchModalComponent,
    VerificationBuroComponent,
    GoogleMapsViewPointComponent,
    SendMailComponent,
    ActionModalsComponent,
    LoadingModalComponent,
    ImageCroppComponent,
    GoogleMapsSelectPointComponent,
    HomeVerificationComponent,
    ViewWarrantyComponent,
    ViewAvalComponent,
    DocumentationCreditComponent,
    ChangeDisbursementDateComponent,
    ChangeAuthorizedAmountComponent,
    ChangeStatusCreditComponent,
    ChangePeriodCreditComponent,
    ValidationListCreditPLDComponent,
    ChangeOriginResourcesComponent,
    ChangeDisbursementTypeComponent,
    ChangeWarrantyComponent,
    CancelCreditComponent,
    PasswordConfirmComponent,
    RegisterTransferenceComponent,
    RegisterCheckComponent,
    ViewDataBlackListComponent,
    UserConfirmComponent,
    GetAuthorizationsComponent,
    UserGetSignatureFingerprintComponent,
    CompleteActionComponent,
    GuidesPaymentsComponent,
    AmountsCondonationComponent
    ]
})
export class ComponentsModule { }
