import gql from 'graphql-tag';

export const addEmployee: any = gql`
  mutation addEmployee(
    $employeeGeneralInfo: JSON,
    $employeeJobInfo: JSON,
    $employeeAddress: JSON,
    $employeeDocuments: JSON,
    $employeeSystem: JSON
  ) {
    addEmployee(
      employeeGeneralInfo: $employeeGeneralInfo,
      employeeJobInfo: $employeeJobInfo,
      employeeAddress: $employeeAddress,
      employeeDocuments: $employeeDocuments,
      employeeSystem: $employeeSystem
    ){
      _id
      employeeCode
      employeeName
    }
  }
`;

export const updateEmployee: any = gql`
mutation updateEmployee(
  $_id: ID,
  $employeeGeneralInfo: JSON,
  $employeeJobInfo: JSON,
  $employeeAddress: JSON,
  $employeeDocuments: JSON,
  $employeeSystem: JSON
) {
  updateEmployee(
    _id: $_id,
    employeeGeneralInfo: $employeeGeneralInfo,
    employeeJobInfo: $employeeJobInfo,
    employeeAddress: $employeeAddress,
    employeeDocuments: $employeeDocuments,
    employeeSystem: $employeeSystem
  ){
    _id
    employeeCode
    employeeName
  }
}
`;

export const updatePassword: any = gql`
mutation updatePassword($pass: String!,$employeeID: ID!) {
  updatePassword(pass: $pass, employeeID: $employeeID)
}
`;

export const deleteEmployee: any = gql`
mutation deleteEmployee($employeeID: ID!, $data: JSON) {
  deleteEmployee(employeeID: $employeeID, data: $data)
}
`;

export const getEmployeeById: any = gql`
query getEmployeeById($_id: ID!) {
  getEmployeeById(_id: $_id) {
    _id
    employeeCode
    employeeName
    employeeGeneralInfo{
      firstName
      lastName
      birthdate
      gender
      email
      phone
      nss
      curp
      notes
    }
    employeeAddress{
      address
      city
      state
      country
      zipCode
    }
    employeeJobInfo{
      manager{
        _id
        employeeCode
        employeeName
      }
      branch{
        _id
        branchCode
        name
      }
      job
      salary
      zone{
        _id
        name
      }
      hireDate
    }
    employeeSystem{
      userType{
        _id
        userTypeName
        levelCode
        userTypeParent{
          _id
        }
      }
      status
      email
    }
    employeeDocuments {
      name
    }
    createdDate
    updatedDate
  }
}
`;


export const viewEmployeeData: any = gql`
query getEmployeeById($_id: ID!) {
  getEmployeeById(_id: $_id) {
    _id
    employeeCode
    employeeName
    employeeGeneralInfo{
      firstName
      lastName
      birthdate
      gender
      email
      phone
      nss
      curp
      notes
    }
    employeeAddress{
      address
      city
      state
      country
      zipCode
    }
    employeeJobInfo{
      manager{
        _id
        employeeCode
        employeeName
      }
      branch{
        _id
        branchCode
        name
      }
      job
      salary
      hireDate
      zone{
        _id
        name
      }
    }
    employeeSystem{
      userType{
        _id
        userTypeName
        levelCode
        userTypeParent{
          _id
        }
      }
      status
      email
      multipleBranch
      allowOperations
      lowReason
      lowDate
      lowNotes
    }
    employeeDocuments {
      name
    }
    createdDate
    updatedDate
  }
}
`;

// Agregar solo las que se vayan necesitando
export const getIDEmployeeByCode: any = gql`
query RootQuery($employeeCode: String){
  getEmployeeByCode(employeeCode:$employeeCode) {
    _id
    employeeCode
    employeeName
  }
}
`;

// Agregar solo las que se vayan necesitando
export const getEmployeeBasicInfoById: any = gql`
query getEmployeeById($_id: ID!) {
  getEmployeeById(_id: $_id) {
    _id
    employeeCode
    employeeName
    employeeGeneralInfo {
      firstName
    }
    employeeSystem{
      lastLocation
      userType{
        _id
        userTypeName
        levelCode
      }
    }
  }
}
`;

export const checkEmployeePassword: any = gql`
query checkEmployeePassword($employeeCode: String!, $password: String!) {
  checkEmployeePassword(employeeCode: $employeeCode, password: $password)
}
`;

export const getListOfEmployees: any = gql`
query getListOfEmployees{
  getListOfEmployees {
    _id
    employeeCode
    employeeName
    employeeGeneralInfo {
      birthdate
      email
      phone
    }
  }
}
`;
export const getListOfMyEmployees: any = gql`
query getListOfMyEmployees{
  getListOfMyEmployees {
    _id
    employeeCode
    employeeName
    employeeGeneralInfo {
      firstName
      lastName
      email
      phone
    }
    employeeJobInfo{
      manager{
        _id
      }
      zone{
        _id
        name
      }
    }
    employeeSystem{
      lastLocation
      userType{
        _id
        userTypeName
      }
    }
  }
}
`;

export const getListOfEmployeesLocation: any = gql`
query getListOfEmployees{
  getListOfEmployees {
    _id
    employeeCode
    employeeName
    employeeGeneralInfo {
      firstName
      lastName
      email
      phone
    }
    employeeSystem{
      lastLocation
    }
  }
}
`;


export const getJobPositions: any = gql`
query getJobPositions{
  getJobPositions {
    JobPosition
  }
}
`;

export const registerLocation: any = gql`
mutation registerLocation(
  $employeeCode: String!,
  $location: JSON
) {
  registerLocation(
    employeeCode: $employeeCode,
    location: $location
  ){
    employeeName
  }
}
`;

export const viewerEmployee: any = gql`
query viewerEmployee($start: Int, $show: Int, $filter: JSON, $sort: JSON){
  viewerEmployee(start: $start, show: $show, filter: $filter, sort: $sort){
    result {
      _id
      employeeCode
      employeeName
      employeeJobInfo{
        manager{
          _id
          employeeCode
          employeeName
        }
        branch{
          _id
          branchCode
          name
        }
        zone{
          _id
          name
        }
      }
      employeeSystem{
        userType{
          _id
          userTypeName
          levelCode
        }
        status
      }
      createdDate
      updatedDate
    }
    pageInfo{
      hasNextPage
      hasPreviousPage
      totalItems
      totalPages
      currentPage
    }
  }
}
`;

export const verifyPasswordEmployee: any = gql`
query verifyPasswordEmployee($pass: String!) {
  verifyPasswordEmployee(pass: $pass)
}
`;

export const autorrizedEmployeeUser: any = gql`
query autorrizedEmployeeUser($user: String!, $pass: String!) {
  autorrizedEmployeeUser(user: $user, pass: $pass)
}
`;

export const changeStatusPermissionEmployee: any = gql`
mutation changeStatusPermissionEmployee($employeeID: ID!, $status: Boolean, $permission: String) {
  changeStatusPermissionEmployee(employeeID: $employeeID, status: $status, permission: $permission )
}
`;

export const getEmployeesSubordinates: any = gql`
query getEmployeesSubordinates($employeeID: ID!) {
  getEmployeesSubordinates(employeeID: $employeeID){
    _id
    employeeCode
    employeeSystem{
      status
    }
  }
}
`;


export const updateZoneEmployee: any = gql`
mutation updateZoneEmployee($zoneID: ID!,$employeeID: ID!) {
  updateZoneEmployee(zoneID: $zoneID, employeeID: $employeeID)
}
`;