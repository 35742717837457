import { Injectable } from '@angular/core';
import {
  getListOfEmployeeWaitingCredits, getListOfEmployeeActiveCredits, registerPay,
  addPayment, getCreditById, registerPayment, loadXLSXNominaBulkLoad, confirmNominaBulkLoad,
  viewerPayment, viewerBulkLoad, getPaymentByID, getEvidencePayment, noAcceptPay, acceptPay,
  getPaymentByIDBasic, updatedPay, deletePay, changePaymentDate, recalculePaymentsCredit,
  condonatePayment, getBulkloadByID, cancelMassiveVharge, getPaymentCreditCondonation
} from '../../model/payment/payment';
import { Apollo } from 'apollo-angular';
import { QueryRef } from 'apollo-angular/QueryRef';

@Injectable()
export class PaymentService {
  private quers: QueryRef<any> = null;

  constructor(private apollo: Apollo) { }

  getCreditById(_id) {
    return this.apollo.watchQuery({ query: getCreditById, variables: { _id }, fetchPolicy: 'network-only' }).valueChanges;
  }

  getListOfEmployeeWaitingCredits(employee) {
    // tslint:disable-next-line:max-line-length
    return this.apollo.watchQuery({ query: getListOfEmployeeWaitingCredits, variables: { employee }, fetchPolicy: 'network-only' }).valueChanges;
  }

  getListOfEmployeeActiveCredits(employee) {
    // tslint:disable-next-line:max-line-length
    return this.apollo.watchQuery({ query: getListOfEmployeeActiveCredits, variables: { employee }, fetchPolicy: 'network-only' }).valueChanges;
  }

  addPayment(form) {
    return this.apollo.mutate({
      mutation: addPayment,
      variables: {
        credit: form.credit,
        employee: form.employee,
        paymentAmount: form.paymentAmount,
        paymentType: form.paymentType,
        paymentBank: form.paymentBank,
        paymentBranch: form.paymentBranch,
        paymentFolio: form.paymentFolio,
        paymentProof: form.paymentProof,
        paymentDate: form.paymentDate,
        paymentReceipt: form.paymentReceipt,
        paymentClients: form.paymentClients,
        note: form.note
      }
    });
  }

  registerPayment(creditID, paymentID, dataPayment) {
    return this.apollo.mutate({
      mutation: registerPayment,
      variables: {
        creditID: creditID,
        paymentID: paymentID,
        dataPayment: dataPayment,
      }
    });
  }

  acceptPay(paymentID, data, notes) {
    return this.apollo.mutate({
      mutation: acceptPay,
      variables: {
        paymentID: paymentID,
        data: data,
        notes: notes,
      }
    });
  }

  noAcceptPay(paymentID, notes) {
    return this.apollo.mutate({
      mutation: noAcceptPay,
      variables: {
        paymentID: paymentID,
        notes: notes,
      }
    });
  }

  registerPay(data) {
    return this.apollo.mutate({
      mutation: registerPay,
      variables: {
        data: data
      }
    });
  }

  updatedPay(data) {
    return this.apollo.mutate({
      mutation: updatedPay,
      variables: {
        data: data
      }
    });
  }

  loadXLSXNominaBulkLoad(data) {
    return this.apollo.mutate({
      mutation: loadXLSXNominaBulkLoad,
      variables: {
        data: data
      }
    });
  }

  confirmNominaBulkLoad(data) {
    return this.apollo.mutate({
      mutation: confirmNominaBulkLoad,
      variables: {
        data: data
      }
    });
  }

  viewerPayment(start, show, filter, sort) {
    return this.apollo.watchQuery({
      query: viewerPayment,
      variables: {
        start: start,
        show: show,
        filter: filter,
        sort: sort
      },
      fetchPolicy: 'network-only'
    }).valueChanges;
  }

  viewerBulkLoad(start, show, filter, sort) {
    return this.apollo.watchQuery({
      query: viewerBulkLoad,
      variables: {
        start: start,
        show: show,
        filter: filter,
        sort: sort
      },
      fetchPolicy: 'network-only'
    }).valueChanges;
  }

  getPaymentByID(paymentID) {
    return this.apollo.watchQuery({
      query: getPaymentByID,
      variables: {
        paymentID: paymentID
      },
      fetchPolicy: 'network-only'
    }).valueChanges;
  }

  getPaymentByIDBasic(paymentID) {
    return this.apollo.watchQuery({
      query: getPaymentByIDBasic,
      variables: {
        paymentID: paymentID
      },
      fetchPolicy: 'network-only'
    }).valueChanges;
  }

  getEvidencePayment(paymentID) {
    return this.apollo.watchQuery({
      query: getEvidencePayment,
      variables: {
        paymentID: paymentID
      },
      fetchPolicy: 'network-only'
    }).valueChanges;
  }

  deletePay(paymentID) {
    return this.apollo.mutate({
      mutation: deletePay,
      variables: {
        paymentID: paymentID
      }
    });
  }

  changePaymentDate(data) {
    return this.apollo.mutate({
      mutation: changePaymentDate,
      variables: {
        data: data
      }
    });
  }

  recalculePaymentsCredit(creditID) {
    return this.apollo.mutate({
      mutation: recalculePaymentsCredit,
      variables: {
        creditID: creditID
      }
    });
  }

  condonatePayment(creditID, paymentID, paymentCode, condonateData, restart) {
    return this.apollo.mutate({
      mutation: condonatePayment,
      variables: {
        creditID: creditID,
        paymentID: paymentID,
        paymentCode: paymentCode,
        condonateData: condonateData,
        restart: restart
      }
    });
  }


  cancelMassiveVharge(bulkloadID) {
    return this.apollo.mutate({
      mutation: cancelMassiveVharge,
      variables: {
        bulkloadID: bulkloadID
      }
    });
  }


  getBulkloadByID(bulkloadID) {
    return this.apollo.watchQuery({
      query: getBulkloadByID,
      variables: {
        bulkloadID: bulkloadID
      },
      fetchPolicy: 'network-only'
    }).valueChanges;
  }

  getPaymentCreditCondonation(paymentId, paymentCode){
    return this.apollo.watchQuery({
      query: getPaymentCreditCondonation,
      variables: {
        paymentID: paymentId,
        paymentCode: paymentCode
      },
      fetchPolicy: 'network-only'
    }).valueChanges;
  }
}
