import { Injectable } from '@angular/core';

@Injectable()
export class ApolloService {
  private dataAPIConnection = {
    protocol: 'https',
    host: 'api.creditoplanreal.com.mx',
    port: '443',
    dir: '/api'
  };

  private currentUri =
    this.dataAPIConnection.protocol +
    '://' +
    this.dataAPIConnection.host +
    ':' +
    this.dataAPIConnection.port +
    this.dataAPIConnection.dir;

  constructor() { }

  getUri() {
    return this.currentUri;
  }
}
