import gql from 'graphql-tag';

export const viewerHistory: any = gql`
query viewerHistory($start: Int, $show: Int, $filter: JSON, $sort: JSON){
  viewerHistory(start: $start, show: $show, filter: $filter, sort: $sort){
    result {
      date
      name
      description
      registerBy{
        _id
        employeeCode
        employeeName
      }
      source
      typeSource
    }
    pageInfo{
      hasNextPage
      hasPreviousPage
      totalItems
      totalPages
      currentPage
    }
  }
}
`;
