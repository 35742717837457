import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import {
  getListOfCountries,
  getListOfStatesByCountryID,
  getListOfCitiesByStateID,
  getCountryByName
} from '../../model/general/paisEstadoCiudad';
import { sendEmail, sendMailFromSystem } from '../../model/general/email.model'
import { uploadImageProfile } from '../../model/general/public.model'
import { viewerHistory } from '../../model/general/history.model'

@Injectable()
export class GeneralService {

  constructor(private apollo: Apollo) { }

  getListOfCountries() {
    return this.apollo.watchQuery({ query: getListOfCountries, fetchPolicy: 'cache-first' }).valueChanges;
  }
  getListOfStatesByCountryID(id) {
    return this.apollo.watchQuery({
      query: getListOfStatesByCountryID,
      variables: { country_id: id }, fetchPolicy: 'cache-first'
    }).valueChanges;
  }
  getListOfCitiesByStateID(id) {
    return this.apollo.watchQuery({
      query: getListOfCitiesByStateID,
      variables: { state_id: id }, fetchPolicy: 'cache-first'
    }).valueChanges;
  }

  getCountryByName(name) {
    return this.apollo.watchQuery({ query: getCountryByName, variables: { name: name } }).valueChanges;
  }

  sendEmail(emailData) {
    return this.apollo.mutate({
      mutation: sendEmail,
      variables: {
        emailData: emailData
      }
    });
  }

  sendMailFromSystem(emailData) {
    return this.apollo.mutate({
      mutation: sendMailFromSystem,
      variables: {
        emailData: emailData
      }
    });
  }

  uploadImageProfile(entity, filename, data) {
    return this.apollo.mutate({
      mutation: uploadImageProfile,
      variables: {
        entity: entity,
        filename: filename,
        data: data
      }
    });
  }

  viewerHistory(start, show, filter, sort) {
    return this.apollo.watchQuery({
      query: viewerHistory,
      variables: {
        start: start,
        show: show,
        filter: filter,
        sort: sort
      },
      fetchPolicy: 'network-only'
    }).valueChanges;
  }

}
