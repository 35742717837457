import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import { routing } from './app.routing';
import { AppSettings } from './app.settings';
import { AppComponent } from './app.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { ApolloModule, Apollo } from 'apollo-angular';
import { onError } from 'apollo-link-error';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SharedModule } from './shared.module';
import { AccountService } from './services/account/account.service';
import { AgmCoreModule } from '@agm/core';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { AngularFireModule } from 'angularfire2';
import { environment } from '../environments/environment';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFireStorageModule } from 'angularfire2/storage';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { createHttpLink } from 'apollo-link-http';
import { ApolloLink } from 'apollo-link';
import ApolloClient from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloService } from './apollo/apollo.service';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
// import { NgxWarehouseModule } from 'ngx-warehouse';

@NgModule({
  declarations: [AppComponent, NotFoundComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ApolloModule,
    // AngularFireModule.initializeApp(environment.firebase),
    // AngularFirestoreModule,
    // AngularFireDatabaseModule,
    // AngularFireStorageModule,
    ToastrModule.forRoot(),
    AgmCoreModule.forRoot(environment.googleMaps),
    SharedModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    AngularMyDatePickerModule,
    routing,
    // NgxWarehouseModule
  ],
  providers: [
    AppSettings,
    AccountService,
    ApolloService,
    // LocalStorage({ prefix: 'braavo' }),
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(apollo: Apollo, toastrService: ToastrService, apolloService: ApolloService) {
    const linkInterface = createHttpLink({ uri: apolloService.getUri() });

    const auth = new ApolloLink((operation, forward) => {
      let token = null;
      if (sessionStorage.getItem('token')) {
        token = sessionStorage.getItem('token');
      }
      if (!token) {
        return forward(operation);
      } else {
        operation.setContext({
          headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem('token') || null,
            'Access-Control-Allow-Origin': '*'
          }
        });
        return forward(operation);
      }
    });

    const error = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
          toastrService.error(message, 'Error Servidor!')
        );
      }
      if (networkError) {
        if (networkError.message === 'Failed to fetch') {
          toastrService.error('Sin conexion con el Servidor', 'Error Conexion!')
        } else {
          console.log(`[Network error]: ${networkError.message}`);
        }
      }
    });

    const link = error.concat(auth.concat(linkInterface));

    const clienteApollo = new ApolloClient({
      link: link,
      cache: new InMemoryCache()
    });

    apollo.setClient(clienteApollo);
  }
}
