import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ComponentsModule } from './pages/components/components.module';
import { NgxMultiModalModule } from 'ngx-multi-modal';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { DownloadService } from './services/general/download.service';
import { CookieService } from 'ngx-cookie-service';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    ComponentsModule,
    NgxMultiModalModule.forRoot({ backdrop: true, container: 'body', size: 'lg', dismissOnBackdropClick: false, keyboard: true }),
    NgxChartsModule,
    AngularMyDatePickerModule
  ],
  exports: [
    HttpClientModule, ComponentsModule, NgxMultiModalModule, NgxChartsModule
  ],
  providers: [
    CookieService,
    DownloadService,
    {
      provide: DeviceDetectorService,
    }
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule
    };
  }
}
