import gql from 'graphql-tag';

export interface Country {
  id: Number;
  name: String;
}

export class Country implements Country {
  id: Number;
  name: String;

  overload_constructor(id: Number, name: String) {
    this.id = id;
    this.name = name;
    return this;
  }

  constructor(country?: Country) {
    Object.assign(this, country);
  }
}

export interface State {
  id: Number;
  name: String;
  cities: City[];
}

export class State implements State {
  id: Number;
  name: String;
  cities: City[];

  overload_constructor(id: Number, name: String) {
    this.id = id;
    this.name = name;
    return this;
  }

  constructor(state?: State) {
    Object.assign(this, state);
  }
}

export interface City {
  id: Number;
  sortname: String;
  name: String;
}

export class City implements City {
  id: Number;
  sortname: String;
  name: String;

  overload_constructor(id: Number, name: String) {
    this.id = id;
    this.name = name;
    return this;
  }

  constructor(city?: City) {
    Object.assign(this, city);
  }
}

// falta get ciudad by estado id
export const getListOfCitiesByStateID: any = gql`
  query getListOfCitiesByStateID($state_id: Int) {
    getListOfCitiesByStateID(state_id: $state_id) {
      id
      name
    }
  }
`;

export const getListOfStatesByCountryID: any = gql`
  query getListOfStatesByCountryID($country_id: Int) {
    getListOfStatesByCountryID(country_id: $country_id) {
      id
      name
    }
  }
`;

export const getListOfCountries: any = gql`
  query getListOfCountries {
    getListOfCountries {
      id
      name
    }
  }
`;

export const getCountryByName: any = gql`
query getCountryByName($name: String) {
  getCountryByName(name: $name) {
    id
    name
  }
}
`;
