import gql from 'graphql-tag';

export interface Documento {
  _id: String;
  name: String;
  file: String;
  uploadedBy: JSON;
  verified: String;
  verifiedBy: JSON;
  verifiedDate: Date;
  createdDate: Date;
  updatedDate: Date;
}

export const getDocumentById: any = gql`
query getDocumentById($_id: ID!) {
  getDocumentById(_id: $_id) {
    _id
    name
    file
    uploadedBy{
      _id
      employeeName
    }
    verified
    verifiedBy{
      _id
      employeeName
    }
    verifiedDate
    createdDate
    updatedDate
  }
}
`;
export const getDocuments: any = gql`
query getDocuments($entity: String!, $_id: ID!, $client: ID) {
  getDocuments(entity: $entity, _id: $_id, client: $client) {
    _id
    name
    uploadedBy{
      _id
      employeeName
    }
    verified
    verifiedBy{
      _id
      employeeName
    }
    verifiedDate
    createdDate
    updatedDate
  }
}
`;

export const uploadDocument: any = gql`
mutation uploadDocument(
  $entity: String!,
  $_id: ID!,
  $documento: JSON,
  $client: ID
) {
  uploadDocument(
    entity: $entity,
    _id: $_id,
    documento: $documento,
    client: $client
  ){
    _id
    name
    uploadedBy{
      _id
      employeeName
    }
    verified
    verifiedBy{
      _id
      employeeName
    }
    verifiedDate
    createdDate
    updatedDate
  }
}
`;


// Archivos en servidor

export const uploadDocumentServer: any = gql`
  mutation uploadDocumentServer($document: JSON, $type: String, $id: ID!, $entity: String!) {
    uploadDocumentServer(document: $document, type: $type, id: $id, entity: $entity)
  }
`;

export const getDocumentServer: any = gql`
  query getDocumentServer($type: String!, $id: ID!, $data: JSON!, $entity: String!) {
    getDocumentServer(type: $type, id: $id, data: $data, entity: $entity)
  }
`;

export const getStatusDocumentServer: any = gql`
  query getStatusDocumentServer($type: String!, $id: ID!, $data: JSON!, $entity: String!) {
    getStatusDocumentServer(type: $type, id: $id, data: $data,  entity: $entity)
  }
`;

export const removeDocumentServer: any = gql`
  mutation removeDocumentServer($type: String!, $id: ID!, $data: JSON, $entity: String!) {
    removeDocumentServer(type: $type, id: $id, data: $data,  entity: $entity)
  }
`;
