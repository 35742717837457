import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { DeviceDetectorService  } from 'ngx-device-detector';
declare function downloadFile(data: String, type: String, name: String): void;

@Injectable()
export class DownloadService {

  constructor(private deviceService: DeviceDetectorService ) { }

  public extractData(data: string, type: string, name: string) {
    if (this.deviceService.userAgent === 'BRAAVO') {
      // 1 console.log('Descargando desde la app');
      downloadFile(data, type, name);
    } else {
      // 1 console.log('Descargando desde el cliente');
      const myBlob: Blob = this.base64ToArrayBuffer(data, type, 512);
      switch (type) {

        case 'image/png':
        case 'image/jpg':
        case 'image/jpeg':
        case 'application/pdf': {
          this.openNewTab(myBlob);
          break;
        }

        default: {
          saveAs(myBlob, name);
          break;
        }
      }
    }
  }

  private base64ToArrayBuffer(base64, contentType, sliceSize): Blob {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;
    const byteCharacters = atob(base64);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  private openNewTab(data: Blob) {
    const fileURL = URL.createObjectURL(data);
    window.open(fileURL, '_blank');
  }
}
