import gql from 'graphql-tag';

export const getUserTypeById: any = gql`
  query getUserTypeById($_id: ID!) {
    getUserTypeById(_id: $_id) {
      _id
      userTypeCode
      userTypeName
      levelCode
      userTypeParent{
        _id
        userTypeName
      }
      permisos
    }
  }
`;

export const getListOfMyUserTypes: any = gql`
  query getListOfMyUserTypes {
    getListOfMyUserTypes {
      _id
      userTypeCode
      userTypeName
      userTypeParent{
        _id
        userTypeName
      }
      permisos
    }
  }
`;

export const getListOfUserTypes: any = gql`
  query getListOfUserTypes {
    getListOfUserTypes {
      _id
      userTypeCode
      userTypeName
      levelCode
      userTypeParent{
        _id
        userTypeName
      }
    }
  }
`;

export const getListOfUserTypesToLevel: any = gql`
  query getListOfUserTypesToLevel($levelCode: Int!) {
    getListOfUserTypesToLevel(levelCode: $levelCode) {
      _id
      userTypeCode
      userTypeName
      levelCode
      userTypeParent{
        _id
        userTypeName
      }
    }
  }
`;

export const addUserType: any = gql`
  mutation addUserType(
    $userTypeName: String!
    $userTypeParent: String
    $levelCode: Int!
    $permisos: JSON!
  ) {
    addUserType(
      userTypeName: $userTypeName
      userTypeParent: $userTypeParent
      levelCode: $levelCode
      permisos: $permisos
    ) {
      _id
    }
  }
`;

export const updateUserType: any = gql`
mutation updateUserType(
  $_id: ID!
  $userTypeName: String!
  $userTypeParent: String
  $levelCode: Int!
  $permisos: JSON!
) {
  updateUserType(
    _id: $_id
    userTypeName: $userTypeName
    userTypeParent: $userTypeParent
    levelCode: $levelCode
    permisos: $permisos
  )
}
`;

export const deleteUserType: any = gql`
  mutation deleteUserType($_id: ID) {
    deleteUserType(_id: $_id) {
      _id
    }
  }
`;
