<div class="d-flex justify-content-center align-items-center w-100 h-100 error-container">
    <div class="col-xl-4 col-md-6 col-10">
        <div class="card border-0 box-shadow rounded-0">
            <div class="card-header d-flex justify-content-center align-items-center border-0 box-shadow">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            </div>
            <div class="card-block text-center">

                <h1 class="display-1">404</h1>
                <h6 class="card-title">Esta pagina no existe.</h6>
                <small>{{router.url}}</small>
                <p class="card-text"> .</p>
                <div class="form-group">
                    <button class="btn" type="button" (click)="goToStart()">
                        <i class="fa fa-search"></i>Ir al inicio</button>
                </div>
            </div>
        </div>
    </div>
</div>